import React from 'react'
import ErrorArea from '../containers/404'
import SEO from '../components/PageSEO';

const NotFoundPage = () => {
    return (
        <>
            <SEO title="Sayfa Bulunamadı | 404 | Furkan Acar" />
            <ErrorArea />
        </>
    )
}

export default NotFoundPage;